import { useState } from 'react'

import { ChooseRunbookType } from 'main/components/workspace/modals/create-runbook/choose-runbook-type'
import { CreateRunbooksModal } from 'main/components/workspace/modals/create-runbook/create-runbooks-modal'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { ConfigModel } from 'main/data-access'

export const CreateRunbookConnector = () => {
  const [isMounted, setIsMounted] = useState(false)
  const isSimplifiedRunbookCreate = ConfigModel.useIsFeatureEnabled('react_runbook_and_template_create')
  const [isOpenNewCreateRunbookModal, setIsOpenNewCreateRunbookModal] = useState(isSimplifiedRunbookCreate)

  const handleOnMount = () => {
    setIsMounted(true)
  }

  const handleOnUnmount = () => {
    setIsMounted(false)
  }

  return (
    <ReactAngularConnector
      mountEvent="open-choose-runbook-type-modal"
      unmountEvent="close-choose-runbook-type-modal"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {isMounted &&
        (isSimplifiedRunbookCreate ? (
          <CreateRunbooksModal
            open={isOpenNewCreateRunbookModal}
            onClickClose={() => setIsOpenNewCreateRunbookModal(false)}
          />
        ) : (
          <ChooseRunbookType />
        ))}
    </ReactAngularConnector>
  )
}
