import { memo, useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useWindowSize } from 'react-use'

import {
  Box,
  Menu,
  NoResourceFound,
  Text,
  useFilterPanelOpenState,
  useRightPanelLayoutOpenState,
  useUpdateEffect
} from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useAppliedFilters, useClearAllFilterState } from 'main/recoil/data-access'
import { TaskListHeader } from './task-list-header'
import { useRootTaskCreateForm } from './task-item/task-item-create'
import { useTaskListHotkeys } from './use-task-list-hotkeys'
import { TaskListItem } from './task-item/task-list-item'
import { useRightPanelTypeValue } from 'main/components/layout/right-panel'
import { ActiveRunbookVersionModel, RunbookViewModel, TaskModel } from 'main/data-access'
import { useStickyDates } from './use-sticky-dates'
import { useTaskListInitialTask } from './use-task-list-initial-task'

const TASK_LIST_HEADERS_HEIGHT = 200
const TASK_ITEM_HEIGHT = 52

// TODO: pull this out for suspense to not render the component content until tasks exist
export const TaskList = memo(() => {
  useTaskListHotkeys()

  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const clearAllFilters = useClearAllFilterState()
  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  // This is a performance optimization which allow the filter panel to show changes before the task list
  // updates for a smoother experience when clicking filter controls on slower machines.
  const deferredFilteredTaskIds = useDeferredValue(filteredTaskIds)
  const { can: canCreateRootTask } = RunbookViewModel.usePermission('create:tasks')
  const rootTaskCreateForm = useRootTaskCreateForm({ taskCount: deferredFilteredTaskIds.length })
  const activeTimezone = RunbookViewModel.useGet('activeTimezone')

  return (
    <>
      {!canCreateRootTask && deferredFilteredTaskIds.length === 0 ? (
        <NoResourceFound context="task" clearAllFilters={clearAllFilters} />
      ) : (
        <>
          <TaskListHeader canCreateRootTask={canCreateRootTask} activeTimezone={activeTimezone} />
          {rootTaskCreateForm}
          <TaskListContent key={runbookVersionId} ids={deferredFilteredTaskIds} activeTimezone={activeTimezone} />
        </>
      )}
    </>
  )
})

const TaskListContent = memo(
  ({ ids: filteredIds, activeTimezone }: { ids: number[]; activeTimezone?: string | null }) => {
    const [currentItemIndex, setCurrentItemIndex] = useState(-1)
    const allTaskIds = TaskModel.useGetIds()
    const filterState = useAppliedFilters()
    const isHighlightMode = RunbookViewModel.useGet('highlightMode')
    const listIds = isHighlightMode ? allTaskIds : filteredIds
    const { height } = useWindowSize()
    const scrollSize = height - TASK_LIST_HEADERS_HEIGHT
    const { taskId: editingTaskId } = useRightPanelTypeValue('task-edit')
    const { taskId: commentsViewingTaskId } = useRightPanelTypeValue('runbook-comments')
    const { isReadyRender, isReadyShow, initialScrollIndex, hasInitialScroll } = useTaskListInitialTask({
      listIds
    })
    const virtuosoRef = useRef<VirtuosoHandle>(null)
    const listRef = useRef<HTMLElement | null>(null)
    const { t } = useLanguage('runbook', { keyPrefix: 'taskList' })

    const { handleScroll, reset } = useStickyDates({
      wrapperId: 'react-task-list-wrapper',
      stickyDayId: 'react-sticky-day',
      stickyTimeId: 'react-sticky-time',
      stickyLocalTimeId: activeTimezone ? 'react-sticky-local-time' : null,
      itemHeight: TASK_ITEM_HEIGHT
    })

    useEffect(() => {
      if (virtuosoRef.current) {
        reset()
        virtuosoRef.current.scrollToIndex(0)
      }
    }, [filterState])

    const onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        let nextIndex: number | null = null

        if (e.code === 'ArrowUp') {
          nextIndex = Math.max(0, currentItemIndex - 1)
        } else if (e.code === 'ArrowDown') {
          nextIndex = Math.min(listIds.length - 1, currentItemIndex + 1)
        }

        if (nextIndex !== null && virtuosoRef) {
          virtuosoRef.current?.scrollIntoView({
            index: nextIndex,
            behavior: 'auto',
            done: () => {
              setCurrentItemIndex(nextIndex as number)
            }
          })
          e.preventDefault()
        }
      },
      [currentItemIndex, listIds]
    )

    const scrollerRef = useCallback(
      (element: HTMLElement | Window | null) => {
        if (element) {
          ;(element as HTMLElement).addEventListener('keydown', onKeyDown)
          listRef.current = element as HTMLElement
        } else {
          listRef?.current?.removeEventListener('keydown', onKeyDown)
        }
      },
      [onKeyDown]
    )

    const renderItemContent = useCallback(
      (
        index: number,
        _: any,
        {
          currentItemIndex,
          listIds,
          editingTaskId,
          commentsViewingTaskId,
          isHighlightMode,
          filteredIds,
          activeTimezone
        }: {
          currentItemIndex: number
          listIds: number[]
          editingTaskId: number | undefined
          commentsViewingTaskId: number | undefined
          isHighlightMode: boolean
          filteredIds: number[]
          activeTimezone: string | null | undefined
        }
      ) => {
        const id = listIds[index]
        const previousTaskId = listIds[index - 1]
        const nextTaskId = listIds[index + 1]
        const isFocused = index === currentItemIndex

        return (
          <TaskListItem
            isEditing={editingTaskId === id || commentsViewingTaskId === id}
            id={id}
            nextTaskId={nextTaskId}
            previousTaskId={previousTaskId}
            isFocused={isFocused}
            index={index}
            onFocus={setCurrentItemIndex}
            isFaded={isHighlightMode ? !filteredIds.includes(id) : undefined}
            activeTimezone={activeTimezone}
          />
        )
      },
      []
    )

    return (
      <>
        <Box css="position: absolute; left: 48px; top: 79px; z-index: 1; display: flex; flex-direction: row;">
          <Text
            color="text-light"
            css="text-align: right; width: 52px; white-space: nowrap;"
            id="react-sticky-day"
          ></Text>
          <Text color="text-light" css="text-align: right; width: 46px;" id="react-sticky-time"></Text>
          {activeTimezone && (
            <Text color="text-light" css="text-align: right; width: 46px;" id="react-sticky-local-time"></Text>
          )}
        </Box>
        <TaskItemMenu />
        {isReadyRender && (
          <Virtuoso
            ref={virtuosoRef}
            data-testid="react-task-list-wrapper"
            style={{ height: `${scrollSize}px`, visibility: isReadyShow ? 'visible' : 'hidden' }}
            totalCount={listIds.length}
            increaseViewportBy={scrollSize}
            computeItemKey={(index: number) => listIds[index]}
            react18ConcurrentRendering
            defaultItemHeight={!hasInitialScroll ? TASK_ITEM_HEIGHT : undefined}
            fixedItemHeight={!hasInitialScroll ? TASK_ITEM_HEIGHT : undefined}
            initialTopMostItemIndex={initialScrollIndex}
            context={{
              currentItemIndex,
              listIds,
              editingTaskId,
              commentsViewingTaskId,
              isHighlightMode,
              filteredIds,
              activeTimezone
            }}
            scrollerRef={scrollerRef}
            onScroll={handleScroll}
            aria-label={t('title')}
            id="react-task-list-wrapper"
            itemContent={renderItemContent}
          />
        )}
      </>
    )
  }
)

const TaskItemMenu = memo(() => {
  const defaultRef = useRef<HTMLElement>(null)
  const { open, triggerRef, items, type, minWidth, maxWidth, maxHeight } = RunbookViewModel.useGet('menu')

  const clearMenu = RunbookViewModel.useAction('taskMenu:clear')
  const setMenuClosed = RunbookViewModel.useAction('taskMenu:close')

  const isRightPanelOpen = useRightPanelLayoutOpenState()
  const isFilterPanelOpen = useFilterPanelOpenState()

  const menuAlignment = useMemo(() => {
    if (type === 'options') {
      return 'end'
    } else {
      return 'start'
    }
  }, [type])

  useUpdateEffect(() => {
    if (open) {
      clearMenu()
      setMenuClosed()
    }
  }, [isRightPanelOpen, isFilterPanelOpen])

  const handleClose = useCallback((e: any) => {
    setMenuClosed()
    e?.reason !== 'blur' && clearMenu()
  }, [])

  return (
    <Menu
      isOpen={open}
      triggerRef={triggerRef ?? defaultRef}
      align={menuAlignment}
      items={items}
      //@ts-ignore
      onClose={handleClose}
      minWidth={minWidth}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      data-testid="task-item-menu"
      arrow
    />
  )
})
