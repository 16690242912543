import { CamelToSnakeCaseNested } from '@cutover/utility-types'
import { CustomField, FieldValue } from './custom-fields'

// NOTE: please be careful here - i suspect these are actually all capitalised eg 'Success' 'Running'
// if you're looking for the response from the api please see below
export type IntegrationFinishedStatus = 'Failed' | 'Success' | 'Cancelled'
export type IntegrationRunningStatus = 'Connect' | 'Running'
export type IntegrationStatus = IntegrationFinishedStatus | IntegrationRunningStatus | 'Queued' | 'Rehearsal'

export const INTEGRATION_FINISHED_STATUSES: IntegrationFinishedStatus[] = ['Failed', 'Success', 'Cancelled']
export const INTEGRATION_RUNNING_STATUSES: IntegrationRunningStatus[] = ['Connect', 'Running']

export type IntegrationSettingsResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  integration_settings: IntegrationSetting[]
}

export type IntegrationSetting = {
  id: number
  key?: string
  archived: boolean
  enabled: boolean
  image_url?: string
  integration: string
  integration_title: string
  name: string
  usage: string
  integration_action_items?: IntegrationActionItem[]
  settings?: Record<string, IntegrationOrActionSetting>
}

export type IntegrationActionItem = {
  id: number
  key: string
  archived: boolean
  created_at: number | string
  entity: Partial<{
    id: number
    account_id: number | null
    global: boolean
    auto_start: boolean
    enable_start_fixed: boolean
    visibility: number
    name: string
    auto_finish: boolean
    default_duration: number
    comms: string
    icon: string
    highlight: boolean
    default: boolean
    is_primary: boolean
    created_at: string
    updated_at: string
    archived: boolean
    conditional_progression: boolean
    enable_webhooks: boolean
    enable_end_fixed: boolean
    key: string
    linkable: boolean
  }>
  functional_user_id: number
  image_url: string
  integration_action: string
  integration_setting: IntegrationSetting
  name: string
  functional_oauth_user: {
    name?: string
    has_session?: boolean
  }
  on: string[]
  option_overrides: { [x: string]: {} }
  settings: Record<string, IntegrationOrActionSetting | string | number | boolean | {}>
  title: string
  global: boolean
  account_id: number | null
  hooks?: null
  internal: boolean
}

export type IntegrationOrActionSetting = {
  default?: string
  required?: boolean
  validation?: string
  label?: string
  helper?: string
  sensitive?: boolean
  placeholder?: string
  field_type?: string
  field_options?: string[]
  dependency_operator?: string
  tooltip?: string
  group?: string
  min?: string
  dependency?: [
    {
      dependent_setting: string
      value: string | string[]
    }
  ]
}

export type IntegrationEventAPI = Omit<CamelToSnakeCaseNested<IntegrationEvent>, 'status'> & {
  status: 'Failed' | 'Success' | 'Cancelled' | 'Connect' | 'Running' | 'Queued' | 'Rehearsal'
}

export type IntegrationEvent = {
  errorReason: string | null
  eventId: string
  integrationActionItemId: number
  integrationOn: string
  newFieldOptionIds: number[] | string[] | null
  progress: string | null
  status: IntegrationStatus
  timestamp: string
  user: number | string | null
}

export type RunbookIntegration = {
  id: number | null
  checked: boolean
  linkableId: number
  linkableType: 'RunbookVersion'
  integrationActionItemId: number | null
  integrationSettingName: string
  inProgress?: boolean
  integrationOptions: { [x: string]: {} | boolean }
  integrationActionItem: RunbookIntegrationActionItem
  customFields: CustomField[]
  fieldValues: FieldValue[]
  integrationEvents: IntegrationEvent[]
  createdAt: string
  updatedAt: string
  order: number
}

export type RunbookIntegrationActionItem = {
  id: number
  key: string
  name: string
  integrationAction: string
  on: string[]
  imageUrl: string
  optionOverrides: { [x: string]: {} }
  settings: {
    location: string | undefined
  }
  archived: boolean
}
