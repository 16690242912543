import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValueLoadable, useResetRecoilState } from 'recoil'
import { produce } from 'immer'
import { keyBy } from 'lodash'

import { LoadableType } from '@cutover/utility-types'
import {
  accountComputedCustomFields,
  accountResponseState_INTERNAL,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  runbookViewState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { GetAccountResponseType } from 'main/services/api/data-providers/account/use-get-account-data'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { GlobalStateSetupModel, ResponseNameToReturnRecord } from 'main/data-access/models/global-state-setup-model'
import { RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'
import { GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import { TaskListResponseType } from 'main/services/queries/use-tasks'
import { taskListResponseDataHelpers } from './task-list-response-data-helpers'
import { accountResponseDataHelpers } from './active-account-response-data-helpers'
import { useIsFeatureEnabled } from '../context'

/* -------------------------------------------------------------------------- */
/*                                    Load                                    */
/* -------------------------------------------------------------------------- */

export const useSetGlobalState: GlobalStateSetupModel['useSet'] = type => {
  useEnsureStableArgs(type)

  /* eslint-disable react-hooks/rules-of-hooks */
  switch (type) {
    case 'account':
      return useSetAccount() as typeof useSetAccount
    case 'runbook':
      return useSetRunbook() as typeof useSetRunbook
    case 'runbookVersion':
      return useSetRunbookVersion() as typeof useSetRunbookVersion
    case 'tasks':
      return useSetTasks() as typeof useSetTasks
    default:
      throw new Error(`Unknown type: ${type}`)
    /* eslint-enable react-hooks/rules-of-hooks */
  }
}

const useSetAccount = () => {
  const conditionalProgressionEnabled = useIsFeatureEnabled('conditional_logic')

  return useRecoilCallback(({ snapshot, transact_UNSTABLE }) => (response: GetAccountResponseType) => {
    const maybeRunbook = snapshot.getLoadable(runbookResponseState_INTERNAL).valueMaybe()?.runbook
    const hasAccountRunbook = maybeRunbook?.account_slug === response.account.slug

    transact_UNSTABLE(({ set, get }) => {
      const computedCondProgIntCFs = snapshot.getLoadable(accountComputedCustomFields).valueMaybe()
      const extendedAccountResponse = accountResponseDataHelpers.extend(response, {
        features: { conditionalLogic: conditionalProgressionEnabled },
        previousCFLookups: computedCondProgIntCFs
      })

      if (extendedAccountResponse.computed.customFields !== computedCondProgIntCFs)
        set(accountComputedCustomFields, extendedAccountResponse.computed.customFields)

      set(accountResponseState_INTERNAL, extendedAccountResponse)

      if (hasAccountRunbook) {
        // `runbookViewState_INTERNAL.notifications` is set in two places here since account
        // and runbook responses arrive separately, ensuring optimal performance.
        // Ref: RB-2838
        const runbookResponse = get(runbookResponseState_INTERNAL)
        const runbookTypes = keyBy(extendedAccountResponse.meta.runbook_types, 'id')
        set(runbookViewState_INTERNAL, prev =>
          produce(prev, draft => {
            if (runbookTypes[runbookResponse.runbook.runbook_type_id].disable_notifications) {
              draft.notifications = 'off'
            }
          })
        )
      }
    })
  })
}

const useSetRunbook = () => {
  return useRecoilCallback(({ set, snapshot, transact_UNSTABLE }) => (response: RunbookGetResponse) => {
    const maybeAccount = snapshot.getLoadable(accountResponseState_INTERNAL).valueMaybe()?.account
    const hasRunbookAccount = response.runbook.account_slug === maybeAccount?.slug

    if (!hasRunbookAccount) {
      set(runbookResponseState_INTERNAL, response)
    } else {
      transact_UNSTABLE(({ set, get }) => {
        set(runbookResponseState_INTERNAL, response)
        const accountState = get(accountResponseState_INTERNAL)
        if (!accountState) return
        const runbookTypes = keyBy(accountState.meta.runbook_types, 'id')

        //`notifications` is set in two places since account & runbook responses arrive separately (perf optimization).
        // Ref: RB-2838
        set(runbookViewState_INTERNAL, prev =>
          produce(prev, draft => {
            if (runbookTypes[response.runbook.runbook_type_id].disable_notifications) {
              draft.notifications = 'off'
            }
          })
        )
      })
    }
  })
}

const useSetRunbookVersion = () =>
  useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (response: GetRunbookVersionResponse) => {
        set(runbookVersionResponseState_INTERNAL, response)
      },
    []
  )

const useSetTasks = () =>
  useRecoilTransaction_UNSTABLE(
    ({ set, get }) =>
      (response: TaskListResponseType) => {
        const rbvResponse = get(runbookVersionResponseState_INTERNAL)
        if (!rbvResponse?.meta?.runbook_components) throw new Error('No runbook version request data to create lookups')

        set(
          taskListResponseState_INTERNAL,
          taskListResponseDataHelpers.extend(response, { runbookComponents: rbvResponse.meta.runbook_components })
        )
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                    Clear                                   */
/* -------------------------------------------------------------------------- */

export const useResetGlobalState: GlobalStateSetupModel['useReset'] = type => {
  const resetAccount = useResetRecoilState(accountResponseState_INTERNAL)
  const resetRunbook = useResetRecoilState(runbookResponseState_INTERNAL)
  const resetRunbookVersion = useResetRecoilState(runbookVersionResponseState_INTERNAL)
  const resetTasks = useResetRecoilState(taskListResponseState_INTERNAL)

  switch (type) {
    case 'account':
      return resetAccount
    case 'runbook':
      return resetRunbook
    case 'runbookVersion':
      return resetRunbookVersion
    case 'tasks':
      return resetTasks
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}

/* -------------------------------------------------------------------------- */
/*                                  Loadable                                  */
/* -------------------------------------------------------------------------- */

export const useLoadableGlobalState = <TType extends keyof ResponseNameToReturnRecord>(type: TType) => {
  useEnsureStableArgs(type)

  /* eslint-disable react-hooks/rules-of-hooks */
  switch (type) {
    case 'account':
      return useRecoilValueLoadable(accountResponseState_INTERNAL) as LoadableType<ResponseNameToReturnRecord[TType]>
    case 'runbook':
      return useRecoilValueLoadable(runbookResponseState_INTERNAL) as LoadableType<ResponseNameToReturnRecord[TType]>
    case 'runbookVersion':
      return useRecoilValueLoadable(runbookVersionResponseState_INTERNAL) as LoadableType<
        ResponseNameToReturnRecord[TType]
      >
    case 'tasks':
      return useRecoilValueLoadable(taskListResponseState_INTERNAL) as LoadableType<ResponseNameToReturnRecord[TType]>
    default:
      throw new Error(`Unknown type: ${type}`)
    /* eslint-enable react-hooks/rules-of-hooks */
  }
}
