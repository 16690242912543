import { forwardRef, memo, MouseEventHandler, Ref, SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import {
  Avatar,
  AvatarGroup,
  AvatarSubject,
  Box,
  CheckboxToggle,
  DependencyType,
  duration as durationFormatter,
  Icon,
  IconButton,
  IconName,
  Pill,
  SpinnerIcon,
  TaskItemIcon,
  TaskItemIconProps,
  TaskItemLayout,
  Text,
  TextLink,
  Tooltip,
  useControlledMenuTriggerRef
} from '@cutover/react-ui'

export type IntegrationStatusProps = {
  status: string
  color: string
  error: string | null
  progress?: string | null
}

export type TaskItemDateData = {
  date: string
  time: string
  showDate: boolean
  showTime: boolean
  localTime?: string
  localDayDiff?: string
}

export type TaskItemTextColorData = {
  text: string
  color: string
}

type TaskStage = 'default' | 'startable' | 'in-progress' | 'complete' | 'new'

export type TaskItemProps = {
  isOptionsMenuOpen: boolean
  avatars?:
    | { tooltip: string | boolean; subject?: AvatarSubject }
    | { tooltip: string | boolean; subject: AvatarSubject }[]
  selected?: boolean
  showSkip?: boolean
  persistCommentsButton?: boolean
  criticalPathIndicator?: { border: 'dashed' | 'solid'; tooltip: string }
  customFieldPillLabels?: { label: string; color?: string }[]
  dateData?: TaskItemDateData
  durationPlanned?: number
  disabled?: boolean
  draggable?: boolean
  durationDiff?: TaskItemTextColorData
  dropEnabled?: boolean
  errors?: string[]
  highlight?: boolean
  iconProps: TaskItemIconProps
  integrationImageUrl?: string
  internalId: number
  isLate?: boolean
  isLateStartFixed?: boolean
  isLoadingTeams?: boolean
  activeTimezone?: string | null
  isLinkedArchived?: boolean
  linkedUrl?: string
  name: string
  onChangeSelect: (e: SyntheticEvent) => void
  onClickAvatar: (e: SyntheticEvent, subject?: AvatarSubject) => void
  onClickComments: MouseEventHandler
  onClickError: () => void
  onClickOptions: (triggerRef: Ref<HTMLElement>) => void
  onClickDependencies: (triggerRef: Ref<HTMLElement>, type: DependencyType) => void
  onClickSkip: MouseEventHandler
  onFocus?: () => void
  onClick: () => void
  onDrop: (e: React.DragEvent) => void
  showBorderTop?: boolean
  showAvatarsAsPill?: boolean
  showTaskDueTimes: boolean
  stage?: TaskStage
  startActual?: number
  endFixed: number | null
  duration: number
  startDiff?: number
  streamName: string
  suffixLabel?: TaskItemTextColorData
  nameBold?: boolean
  namePrefixIcon?: IconName
  updating?: boolean
  /** The label for the select checkbox. All props matching ^label* have defaults but are not currently translated. */
  labelSelectCheckbox?: string
  /** The label for the skip button. All props matching ^label* have defaults but are not currently translated. */
  labelSkipButton?: string
  /** The label for the menu button. All props matching ^label* have defaults but are not currently translated. */
  labelMenuButton?: string
  /** The label for the comments button. All props matching ^label* have defaults but are not currently translated. */
  labelCommentsButton?: string
  /** The label for the started text that sits underneath the task name. All props matching ^label* have defaults but are not currently translated. */
  labelStarted?: string
  /** The label for the late text that sits underneath the task name. All props matching ^label* have defaults but are not currently translated. */
  labelLate?: string
  /** The label for the early text that sits underneath the task name. All props matching ^label* have defaults but are not currently translated. */
  labelEarly?: string
  labelElapsed?: string
  labelUserNoLongerOnRunbook?: string
  integrationStatusProps?: IntegrationStatusProps
  taskRtoMarkerLabel?: string
  /** When in highlight mode, this applies to an item that is not included in the filtered tasks array */
  isFaded?: boolean
  isFocused?: boolean
  a11yTitle?: string
}

export const TaskItem = memo(
  forwardRef<HTMLElement, TaskItemProps>(
    (
      {
        isOptionsMenuOpen,
        avatars,
        criticalPathIndicator,
        customFieldPillLabels = [],
        dateData,
        disabled,
        draggable,
        durationDiff,
        durationPlanned,
        dropEnabled,
        errors,
        highlight,
        iconProps,
        integrationImageUrl,
        internalId,
        isLate,
        isLateStartFixed,
        isLoadingTeams,
        activeTimezone,
        isLinkedArchived,
        linkedUrl,
        name,
        nameBold,
        namePrefixIcon,
        onChangeSelect,
        onClickAvatar,
        onClickComments,
        onClickError,
        onClickOptions,
        onClickDependencies,
        onClickSkip,
        onDrop,
        onFocus,
        onClick,
        persistCommentsButton,
        selected,
        showBorderTop,
        showSkip,
        showAvatarsAsPill,
        showTaskDueTimes,
        stage,
        startActual,
        endFixed,
        duration,
        startDiff,
        streamName,
        suffixLabel,
        updating,
        labelCommentsButton = 'Comments',
        labelEarly = 'early',
        labelLate = 'late',
        labelMenuButton = 'More options',
        labelSelectCheckbox = 'Select task',
        labelSkipButton = 'Skip',
        labelStarted = 'Started',
        labelElapsed = 'Elapsed',
        labelUserNoLongerOnRunbook,
        integrationStatusProps,
        taskRtoMarkerLabel,
        isFaded,
        isFocused,
        a11yTitle
      }: TaskItemProps,
      ref
    ) => {
      const optionsMenuRef = useControlledMenuTriggerRef<HTMLButtonElement>()
      const dependenciesMenuRef = useControlledMenuTriggerRef<HTMLButtonElement>()
      const [isDraggedOver, setIsDraggedOver] = useState(false)

      const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault()
        setIsDraggedOver(true)
      }

      const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault()
      }

      const handleDragLeave = () => {
        setIsDraggedOver(false)
      }

      const handleDrop = (e: React.DragEvent) => {
        onDrop?.(e)
        setIsDraggedOver(false)
      }

      return (
        <TaskItemLayout
          ref={ref}
          data-testid="task-list-item"
          dropEnabled={dropEnabled}
          disabled={disabled || isFaded}
          draggable={draggable}
          highlight={selected || highlight}
          selected={selected}
          rtoHighlight={!!taskRtoMarkerLabel}
          loading={updating}
          isDraggedOver={isDraggedOver}
          onClick={onClick}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onFocus={onFocus}
          showBorderTop={showBorderTop}
          isFocused={isFocused}
          a11yTitle={a11yTitle}
          prefixContent={
            <Box flex={false} width="30px" pad={{ left: '8px' }}>
              <CheckboxToggle
                label={`${labelSelectCheckbox} ${name}`}
                isChecked={!!selected}
                onClick={onChangeSelect}
                className={selected ? undefined : 'task-item-display-on-task-hover'}
              />
            </Box>
          }
          dateData={dateData}
          isLateStartFixed={isLateStartFixed}
          activeTimezone={activeTimezone}
          icon={
            <TaskItemIcon
              ref={dependenciesMenuRef}
              {...iconProps}
              onClickDependency={type => onClickDependencies(dependenciesMenuRef, type)}
            />
          }
          mainContentPrefix={
            namePrefixIcon ? (
              <Icon icon={namePrefixIcon} color="text-light" />
            ) : integrationImageUrl ? (
              <TaskListIntegrationImage src={integrationImageUrl} />
            ) : null
          }
          mainContent={
            <Text
              truncate="tip"
              tip={name}
              tipPlacement="top"
              weight={nameBold ? 'bold' : 'normal'}
              data-testid="task-item-title"
            >
              <Text color="text-light">#{internalId}</Text>{' '}
              {linkedUrl ? (
                !isLinkedArchived ? (
                  <TextLink
                    href={linkedUrl}
                    label={name}
                    onClick={e => e.stopPropagation()}
                    openInNew
                    css="padding-right: 4px;"
                    inline
                  />
                ) : (
                  <Text css="padding-right: 4px;">{name} (archived)</Text>
                )
              ) : (
                <Text color={isLate ? 'warning' : undefined} css="padding-right: 4px;">
                  {name}
                </Text>
              )}
              {taskRtoMarkerLabel && <Pill color="grey" label={taskRtoMarkerLabel}></Pill>}
              <>
                {customFieldPillLabels.map(item => (
                  <Pill
                    {...item}
                    key={`${item.label}-${item.color}`}
                    css="margin-left: 4px;"
                    data-testid={`custom-field-pill-${item.label}`}
                  />
                ))}
              </>
            </Text>
          }
          mainContentSubLine={
            <Text size="xsmall" color="text-light" truncate className="task-item-display-on-task-hover">
              {streamName}
              {typeof startDiff === 'number' && (
                <>
                  <>&nbsp;•&nbsp;</> {labelStarted}
                  <Text size="xsmall" color={startDiff > 0 ? 'warning' : 'success'} data-testid="task-start-diff-text">
                    &nbsp;{durationFormatter(Math.abs(startDiff))}&nbsp;
                  </Text>
                  {startDiff > 0 ? labelLate : labelEarly}
                </>
              )}
              {stage === 'in-progress' && !!startActual && !showTaskDueTimes && (
                <>
                  <>&nbsp;•&nbsp;</>
                  {labelElapsed}
                  &nbsp;
                  <ElapsedTimer startTime={startActual} durationPlanned={durationPlanned ?? 0} />
                </>
              )}
            </Text>
          }
          integrationStatus={integrationStatusProps ? <IntegrationStatus {...integrationStatusProps} /> : undefined}
          hasIntegrationError={!!integrationStatusProps?.error}
          endContent={
            <>
              <Box direction="row" align="center" css="flex: 0 0 auto;">
                {labelUserNoLongerOnRunbook && (
                  <Box height="21px">
                    <Tooltip
                      content={labelUserNoLongerOnRunbook}
                      placement="top"
                      css="display: flex;"
                      hasCursor={false}
                    >
                      <Icon icon="alert" color="text-light" data-testid="user-no-longer-on-runbook-icon"></Icon>
                    </Tooltip>
                  </Box>
                )}
                <Box className={persistCommentsButton ? undefined : 'task-item-display-on-task-hover'}>
                  <IconButton
                    data-testid="comment-task-button"
                    icon="message"
                    label={labelCommentsButton}
                    onClick={onClickComments}
                    tipPlacement="top"
                  />
                </Box>
                {showSkip && (
                  <Box className="task-item-display-on-task-hover">
                    <IconButton
                      data-testid="skip-task-button"
                      icon="skip"
                      label={labelSkipButton}
                      onClick={onClickSkip}
                      tipPlacement="top"
                    />
                  </Box>
                )}

                <Box className={!isOptionsMenuOpen ? 'task-item-display-on-task-hover' : ''}>
                  <IconButton
                    ref={optionsMenuRef as Ref<HTMLButtonElement> | undefined}
                    data-testid="options-task-button"
                    disableTooltip
                    icon="more-vertical"
                    label={labelMenuButton}
                    isActive={isOptionsMenuOpen}
                    onClick={e => {
                      e.stopPropagation()
                      onClickOptions(optionsMenuRef)
                    }}
                  />
                </Box>

                {errors && (
                  <IconButton
                    label={
                      <Box direction="column" gap="xsmall" pad="xxsmall">
                        {errors.map((error, index) => (
                          <Text key={index}>{error}</Text>
                        ))}
                      </Box>
                    }
                    a11yTitle={errors.join(' ')}
                    tipPlacement="top"
                    icon="alert"
                    onClick={onClickError}
                  />
                )}
              </Box>
              {criticalPathIndicator && (
                <Box
                  flex={false}
                  css="position: relative;"
                  alignSelf="center"
                  height="19px"
                  width="19px"
                  border={{ size: '1px', color: 'error', style: criticalPathIndicator.border }}
                  round
                  data-testid="critical-path-indicator"
                >
                  <Tooltip content={criticalPathIndicator.tooltip} placement="top">
                    <Text
                      color="error"
                      size="13px"
                      alignSelf="center"
                      css="display: inline-block; width:17px; height:17px; text-align: center; vertical-align: text-top;"
                    >
                      C
                    </Text>
                  </Tooltip>
                </Box>
              )}
              {!integrationStatusProps && (
                <Box data-testid="task-duration" flex={false} alignSelf="center" direction="row">
                  {showTaskDueTimes && stage === 'in-progress' ? (
                    <ElapsedTimer
                      size="normal"
                      countDown={true}
                      startTime={startActual}
                      endFixed={endFixed}
                      duration={duration}
                    />
                  ) : (
                    <>
                      {suffixLabel && (
                        <Text color={suffixLabel.color} css="white-space: pre;">
                          {suffixLabel.text}
                        </Text>
                      )}
                      {durationDiff && (
                        <Text color={durationDiff.color} css="white-space: pre;">
                          {' ' + durationDiff.text}
                        </Text>
                      )}
                    </>
                  )}
                </Box>
              )}
              {showAvatarsAsPill && avatars && (
                <Box css="max-width: 150px;">
                  <Pill
                    truncate="tip"
                    label={Array.isArray(avatars) ? avatars[0].subject.name ?? '' : avatars.subject?.name ?? ''}
                    color={
                      Array.isArray(avatars)
                        ? avatars[0].subject.color ?? undefined
                        : avatars.subject?.color ?? undefined
                    }
                    data-testid="task-item-user-team-label"
                  />
                </Box>
              )}
            </>
          }
          suffixContent={
            isLoadingTeams ? (
              <SpinnerIcon color="text-light" css="opacity: 0.5;" />
            ) : Array.isArray(avatars) ? (
              <AvatarGroup avatars={avatars} onClickAvatarItem={onClickAvatar} rightPosition={28} />
            ) : (
              <Avatar
                subject={avatars?.subject}
                tooltipPlacement="top"
                tooltip={avatars?.tooltip}
                onClick={onClickAvatar}
                data-testid="task-item-avatar"
              />
            )
          }
        />
      )
    }
  )
)

type ElapsedTimerProps = {
  durationPlanned?: number
  startTime: number | undefined
  countDown?: boolean
  size?: string
  duration?: number
  endFixed?: number | null
}

const ElapsedTimer = ({
  durationPlanned,
  countDown = false,
  size = 'xsmall',
  endFixed,
  startTime,
  duration
}: ElapsedTimerProps) => {
  const [formattedTime, setFormattedTime] = useState<{ color: string; text: string }>({
    color: 'text-light',
    text: ''
  })

  useEffect(() => {
    const updateTime = () => {
      const warningThreshold = 300 // 5m
      const currentTime = Math.floor(Date.now() / 1000)
      const endForecast = startTime && duration ? startTime + duration : 0
      const dueDate = endFixed && endFixed > endForecast ? endFixed : endForecast
      const timeDiff = countDown ? dueDate - currentTime : currentTime - (startTime ?? 0)
      const precision = Math.abs(timeDiff) < 3600 ? 1 : 2

      let color = 'text-light'
      if (countDown) {
        color = timeDiff > warningThreshold ? 'text-light' : timeDiff > 0 ? 'warning' : 'error'
      } else if (durationPlanned && timeDiff > durationPlanned) {
        color = 'warning'
      }

      setFormattedTime({
        text:
          countDown && timeDiff < 0
            ? `${durationFormatter(Math.abs(timeDiff), precision)} Past due`
            : countDown
            ? `Due in ${durationFormatter(timeDiff, precision)}`
            : durationFormatter(timeDiff, precision),
        color
      })
    }

    updateTime()
    const interval = setInterval(updateTime, 1000)
    return () => clearInterval(interval)
  }, [startTime, durationPlanned, countDown])

  return (
    <Text size={size} data-testid="elapsed-time" color={formattedTime.color}>
      {formattedTime.text}
    </Text>
  )
}

const IntegrationStatus = ({ status, color, error, progress }: IntegrationStatusProps) => {
  return (
    <Text
      data-testid="integration-status"
      truncate="tip"
      tipPlacement="top"
      color={color}
      css={`
        white-space: nowrap;
        margin-left: auto;
      `}
    >
      {status} {error ? <>({error})</> : progress ? <>({progress}%)</> : null}
    </Text>
  )
}

const TaskListIntegrationImage = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
`
