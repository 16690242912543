import { memo } from 'react'

import { CheckboxGroup, FilterAccordionPanel } from '@cutover/react-ui'
import { useStageFilter } from 'main/recoil/data-access'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

type RunbookStageFilterValueType = RunbookFilterType['stage']

export const StageGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.labels' })
  const { taskStageTypes: taskStages } = ConfigModel.useGet()
  const [runbookStages, setRunbookStages] = useStageFilter()

  return (
    <FilterAccordionPanel label={t('stage')} applied={!!runbookStages?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('stage')}
        options={taskStages.map(stage => ({
          value: stage.id,
          label: stage.label
        }))}
        value={runbookStages}
        onChange={(event: any) => event && setRunbookStages(event.value as RunbookStageFilterValueType)}
      />
    </FilterAccordionPanel>
  )
})
