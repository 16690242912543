import { Dispatch, SetStateAction } from 'react'
import { Box } from 'grommet'
import parse from 'html-react-parser'

import { Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

type DataViewMarketingModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const DataViewMarketingModal = ({ open, setOpen }: DataViewMarketingModalType) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'workspace.tabs.dataViewsInfoModal' })
  const { cutoverApiFullPath } = ConfigModel.useGet()
  const imgSrc = `${cutoverApiFullPath}/img/data-view/marketing-img.png`

  return (
    <Modal open={open} hideFooter title={t('title')} onClose={() => setOpen(false)}>
      <Box margin={{ bottom: 'medium' }} css="display: block">
        {/* TODO: CFE-2474 maybe use i18n trans component here https://react.i18next.com/latest/trans-component */}
        <Box>{parse(t('body', { link: t('metastoreExternalLink') }))}</Box>
        <br />
        <br />
        <Text weight={600}>{t('contactCSM')}</Text>
      </Box>
      <img src={imgSrc} alt={t('altText')}></img>
    </Modal>
  )
}
