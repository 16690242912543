import { Editor, Element, Text, Transforms } from 'slate'

import { deserializeHtml, sanitizeRawHtml } from '../utils/serializer'
import { createEmptyParagraphNode, getParentBlock } from '../utils'

export const withPasteHtml = (handlePasteFile?: (data: DataTransfer) => void) => (editor: Editor) => {
  editor.insertData = data => {
    const html = data.getData('text/html')
    const files = data.files

    // Custom paste function to externally handle pasting files
    // Note: need the !html check, since sometimes when copying (eg from Numbers), it ALSO puts an image in the clipboard
    if (!html && files && files.length > 0 && handlePasteFile) {
      handlePasteFile(data)
      return
    }

    const currentBlock = getParentBlock(editor)
    const currentBlockType = currentBlock ? currentBlock.type : ''
    const allowPasteHtml = currentBlockType === 'paragraph'

    // Only allow pasting HTML when in a standard paragraph node
    // Currently the safest/easiest way to reduce chance of messing things up
    if (allowPasteHtml && html) {
      const fragment = deserializeHtml(sanitizeRawHtml(html)) as any[]

      if (!fragment) {
        return
      }
      // If current block has content, and pasted content contains blocks, insert a break first (to prevent bugs pasting block elems)
      if (
        Element.isElement(currentBlock) &&
        !Editor.isEmpty(editor, currentBlock) &&
        fragmentContainsBlocks(fragment as any)
      ) {
        editor.insertBreak()
        // Insert an empty paragraph at end of fragment to prevent table breaking if there is content after cursor in current block
        fragment.push(createEmptyParagraphNode())
      }
      Transforms.insertFragment(editor, fragment as any)
    } else {
      const text = data.getData('text/plain')
      if (text) {
        Transforms.insertText(editor, text)
      }
    }
    return
    // No need to continue after this point so not calling insertData(data)
  }

  return editor
}

const fragmentContainsBlocks = (nodes: Node[]) => {
  for (const node of nodes) {
    if (!Text.isText(node as any)) {
      return true // Found a block node
    }
  }
  return false // No block nodes found in the fragment
}
