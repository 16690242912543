import { Element, Text } from 'slate'
import { jsx } from 'slate-hyperscript'

import { Align, CustomElement, CustomText, ImageElement, isAlignableElement, LinkElement } from '../text-editor-types'

// This handles how Slate's data is converted into a string we save to DB
export const serialize = (node: CustomElement | CustomText): string => {
  if (Text.isText(node)) {
    let string = node.text
    let { text: _, ...attrs } = node
    Object.keys(attrs).forEach(key => {
      if (key === 'bold') {
        string = `<strong>${string}</strong>`
      } else if (key === 'italic') {
        string = `<em>${string}</em>`
      } else if (key === 'underline') {
        string = `<u>${string}</u>`
      } else if (key === 'strikethrough') {
        string = `<del>${string}</del>`
      } else if (key === 'superscript') {
        string = `<sup>${string}</sup>`
      } else if (key === 'subscript') {
        string = `<sub>${string}</sub>`
      } else if (key === 'code') {
        string = `<code>${string}</code>`
      } else if (key === 'status') {
        string = `<span data-custom-element-type="status-${attrs[key]}">${string}</span>` //TODO
      }
    })
    return string
  }

  const children = node.children.map(n => serialize(n)).join('')
  const style =
    Element.isElement(node) && isAlignableElement(node) && node.align ? ` style="text-align: ${node.align};"` : ''

  switch (node.type) {
    case 'link':
      return `<a href="${(node as LinkElement).url}">${children}</a>`
    case 'quote':
      return `<blockquote${style}>${children}</blockquote>`
    case 'heading':
      return `<h4${style}>${children}</h4>`
    case 'image':
      return `<img src="${(node as ImageElement).src}" />`
    case 'list-item':
      return `<li${style}>${children}</li>`
    case 'list-item-text':
      return `<p${style}>${children}</p>`
    case 'numbered-list':
      return `<ol${style}>${children}</ol>`
    case 'paragraph':
      return `<p${style}>${children}</p>`
    case 'code':
      return `<pre>${children}</pre>`
    case 'bulleted-list':
      return `<ul${style}>${children}</ul>`
    case 'table':
      return `<table>${children}</table>`
    case 'table-header':
      return `<thead>${children}</thead>`
    case 'table-header-cell':
      return `<th${style}>${children}</th>`
    case 'table-body':
      return `<tbody>${children}</tbody>`
    case 'table-row':
      return `<tr>${children}</tr>`
    case 'table-cell':
      return `<td${style}>${children}</td>`
    case 'message':
      return `<div data-custom-element-type="message-${node.messageType}"${style}>${children}</div>` //TODO
    default:
      return children
  }
}

const ELEMENT_TAGS: { [x: string]: (el: HTMLElement) => Object } = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading' }),
  H2: () => ({ type: 'heading' }),
  H3: () => ({ type: 'heading' }),
  H4: () => ({ type: 'heading' }),
  H5: () => ({ type: 'heading' }),
  H6: () => ({ type: 'heading' }),
  IMG: el => ({ type: 'image', src: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  TABLE: () => ({ type: 'table' }),
  THEAD: () => ({ type: 'table-header' }),
  TH: () => ({ type: 'table-header-cell' }),
  TBODY: () => ({ type: 'table-body' }),
  TR: () => ({ type: 'table-row' }),
  TD: () => ({ type: 'table-cell' }),
  DIV: el => {
    const elemType = el.getAttribute('data-custom-element-type')
    if (elemType) {
      const parsedCustomElem = elemType.split('-')
      if (parsedCustomElem[0] === 'message') {
        return {
          type: 'message',
          messageType: parsedCustomElem[1]
        }
      }
    }
    return {}
  }
}

const INLINE_BOLD_STYLES = ['600', '700', 'bold']

const TEXT_TAGS: { [x: string]: (el: HTMLElement) => Object } = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  SUP: () => ({ superscript: true }),
  SUB: () => ({ subscript: true }),
  SPAN: el => {
    const inlineStyle = el.style
    const elemType = el.getAttribute('data-custom-element-type')
    if (elemType) {
      const parsedCustomElem = elemType.split('-')
      if (parsedCustomElem[0] === 'status') {
        return { status: parsedCustomElem[1] }
      }
    }
    // Deal with some common inline styles when pasting
    let styles = []
    if (INLINE_BOLD_STYLES.includes(inlineStyle.fontWeight)) {
      styles.push('bold')
    }
    if (inlineStyle.fontStyle === 'italic') {
      styles.push('italic')
    }
    if (inlineStyle.textDecoration === 'underline') {
      styles.push('underline')
    }
    if (inlineStyle.textDecoration === 'line-through') {
      styles.push('strikethrough')
    }

    const stylesObject = styles.reduce<Record<string, boolean>>((acc, style) => {
      acc[style] = true
      return acc
    }, {})

    return stylesObject
  }
}

const removeWhitespace = (html: string) => {
  // Removes newlines/whitespace between HTML block tags while preserving it between inline tags
  // Eg we may want to keep whitespace between </strong> and <em> for example, but not between <tr> and <td>

  return html.replace(
    /(<\/?(?:div|ul|ol|li|pre|table|thead|tbody|tr|td|th|blockquote|p)[^>]*>)\s*(<\/?(?:div|ul|ol|li|pre|table|thead|tbody|tr|td|th|blockquote|p)[^>]*>)\s*/g,
    '$1$2'
  )
}

export const sanitizeRawHtml = (html: string) => {
  // Strip style tags & their contents
  let newHtml = html.replace(/<style.*?<\/style>/g, '')

  // Remove html comments
  newHtml = newHtml.replace(/<\!--.*?-->/g, '')

  // Strip all cols and colgroups (separately since cols can exist on their own)
  newHtml = newHtml.replace(/<col[^>]*>/g, '')
  newHtml = newHtml.replace(/<colgroup.*?<\/colgroup>/g, '')

  newHtml = removeWhitespace(newHtml)

  return newHtml
}

export const deserializeHtml = (html: string) => {
  let newHtml = html?.trim().length === 0 ? '<p></p>' : html
  const document = new DOMParser().parseFromString(newHtml, 'text/html')
  return deserialize(document.body)
}

export const deserialize = (el: HTMLElement): Object | any[] | null => {
  if (el.nodeType === Node.TEXT_NODE) {
    if (el.parentNode?.nodeName === 'BODY') {
      return jsx('element', { type: 'paragraph' }, [jsx('text', el.textContent as string)])
    }

    // Note: spaces between tags such as <td> will break the structure
    // Mostly mitigated by the removeWhitespace function above
    // To solve properly we need to detect the source of the paste, eg MS Word, and clean according to that
    return el.textContent
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0] as HTMLElement
  }

  let children = Array.from(parent.childNodes)
    .map(node => deserialize(node as HTMLElement))
    .flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  // We want to ignore divs unless they are special custom elems.
  // We can't turn them into paragraph tags because they may contain other P tags
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el) as CustomElement

    if (!attrs || !Object.keys(attrs).length) {
      // Note: this strips elements we dont support, like DIVs that aren't our own styled ones
      if (onlyHasTextChildren(children)) {
        return jsx('element', { type: 'paragraph' }, children)
      } else {
        return children
      }
    }

    // If this is a paragraph inside an LI, mark it as a 'list-item-text' element
    if (el.parentNode?.nodeName === 'LI' && nodeName === 'P') {
      attrs.type = 'list-item-text'
    }

    // Note: align is currently unused
    // TODO: confirm that we would only ever put this on paragraph link and image.
    if (isAlignableElement(attrs)) {
      attrs.align = el.style.textAlign as Align
    }

    return jsx('element', attrs, children)
  }

  // Note: could be smart & look for styles like monospace font, if so convert to code elem

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    if (!attrs || !Object.keys(attrs).length) {
      return children
    }

    return children.map(child => {
      if (child.children) {
        return addAttrsToChildren(child, attrs)
      }

      return jsx('text', attrs, child)
    })
  }

  return children
}

// NOTE: this deals with issue where a 'block' element like <A> is wrapped in an inline element like <EM>
// Copied from plate editor https://github.com/udecode/plate/pull/83/files
const addAttrsToChildren = (child: any, attrs: any) => {
  if (child.children) {
    child.children = child.children.map((item: any) => {
      const itemWithAttrs = addAttrsToChildren(item, attrs)
      return { ...itemWithAttrs, ...attrs }
    })
  }
  return child
}

const onlyHasTextChildren = (children: (Object | null)[]) => {
  for (const child of children) {
    if (child && child.hasOwnProperty('type')) {
      return false
    }
  }
  return true
}
