import { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { RunbookViewModel, TaskModel } from 'main/data-access'

export const useTaskListHotkeys = () => {
  const canInitiateBulkEditActions = RunbookViewModel.usePermissionCallback('initiate:bulkEditActions')
  const canInitiateBulkProgressionActions = RunbookViewModel.usePermissionCallback('initiate:bulkProgressionActions')
  const deselectAllIds = RunbookViewModel.useAction('selectedIds:removeAll')
  const selectAllIds = RunbookViewModel.useAction('selectedIds:selectAll')
  const selectedIdsValueCallback = RunbookViewModel.useGetCallback('selectedIds')
  const openModal = RunbookViewModel.useAction('modal:open')

  const skipTasks = TaskModel.useAction('bulk_skip')

  const handleSkip = useCallback(async () => {
    const permissions = await canInitiateBulkProgressionActions()
    if (permissions.can) {
      const taskIds = await selectedIdsValueCallback()
      skipTasks(taskIds)
    }
  }, [canInitiateBulkProgressionActions, skipTasks])

  const handleDelete = useCallback(async () => {
    const permissions = await canInitiateBulkEditActions()
    if (permissions.can) openModal({ type: 'tasks-delete', id: await selectedIdsValueCallback() })
  }, [canInitiateBulkEditActions, openModal, selectedIdsValueCallback])

  useHotkeys('meta+a', selectAllIds, { preventDefault: true }, [])
  useHotkeys('meta+d', deselectAllIds, { preventDefault: true }, [])
  useHotkeys('meta+k', handleSkip, { preventDefault: true }, [])
  useHotkeys('Backspace', handleDelete, { preventDefault: true }, [])
}
